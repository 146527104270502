export default class ManagerViewModel {
  constructor() {
    this.name = "";
    this.job = "";
    this.jobgrade = "";
    this.tel = "";
    this.mobile = "";
    this.email = "";

    this.personNum = "";
  }

  setDataFromServer(objRaw) {
    if (objRaw.name) {
      this.name = objRaw.name;
    }
    if (objRaw.job) {
      this.job = objRaw.job;
    }
    if (objRaw.jobgrade) {
      this.jobgrade = objRaw.jobgrade;
    }

    if (objRaw.tel) {
      this.tel = objRaw.tel;
    }
    if (objRaw.mobile) {
      this.mobile = objRaw.mobile;
    }
    if (objRaw.email) {
      this.email = objRaw.email;
    }

    if (objRaw.personNum) {
      this.personNum = objRaw.personNum;
    }
  }
}
