<template>
  <TableViewWithTitle title="담당자 정보">
    <template v-slot:body>
      <fragment v-for="(item, index) in dataList" :key="index">
        <tr>
          <th>이름</th>
          <td>{{ item.name }}</td>
          <th>전화번호 (내선)</th>
          <td>{{ item.tel }}</td>
        </tr>
        <tr>
          <th>직급</th>
          <td>{{ item.jobgrade }}</td>
          <th>휴대전화</th>
          <td>{{ item.mobile }}</td>
        </tr>
        <tr>
          <th>직무</th>
          <td class="td_text">
            {{ item.job }}
          </td>
          <th>이메일</th>
          <td class="td_text">
            {{ item.email }}
          </td>
        </tr>
      </fragment>
    </template>
  </TableViewWithTitle>
</template>

<script>
import TableViewWithTitle from "@/components/shared/tableView/TableViewWithTitle";

export default {
  name: "ManagerInfo",
  components: {
    TableViewWithTitle,
  },
  props: {
    dataProps: Object,
  },
  computed: {
    dataList() {
      return this.dataProps.dataList;
    },
  },
};
</script>
