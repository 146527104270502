<template>
  <InputBase :class="{ disabled: isDisabled, tf_error: isError }">
    <input
      ref="input"
      v-model="input"
      :data-ellip="dataEllip"
      :class="inputClass"
      :placeholder="placeholder"
      :disabled="isDisabled"
      :maxLength="maxLength"
      @focus="onFocus"
      @blur="onBlur"
      @keyup.13="onKeyUpEnter"
    />
  </InputBase>
</template>

<script>
import InputBase from "@/components/common/input/InputBase";
import { getCanConvertNumber } from "@/utils/numberUtils";
import { getMoneyToNumber, addCommaForMoney } from "@/utils/stringUtils";

export default {
  name: "InputAutoMoney",
  components: {
    InputBase,
  },
  props: {
    inputClass: String,
    value: String,
    placeholder: String,
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isError: {
      type: Boolean,
      default: false,
    },
    maxLength: Number,
    dataEllip: String,
  },
  data() {
    return {
      selectionEnd: 0,
      digit: 0,
    };
  },
  computed: {
    input: {
      get() {
        const validText = this.getValidText(this.value);

        if (this.maxLength && this.value) {
          if (this.value.length > this.maxLength) {
            this.$emit("update:value", this.value.slice(0, this.maxLength));
          }
        }

        if (this.$refs.input) {
          let selectionEnd = 0;
          if (this.value.length % 4 === 0) {
            selectionEnd = this.$refs.input.selectionEnd + 1 || 0;
          } else {
            selectionEnd = this.$refs.input.selectionEnd || 0;
          }
          this.$refs.input.setSelectionRange(selectionEnd, selectionEnd);

          setTimeout(() => {
            this.$refs.input.setSelectionRange(selectionEnd, selectionEnd);
          }, 0);
        }

        return getMoneyToNumber(this.value).toLocaleString();
      },
      set(value) {
        this.$emit("update:value", value);
      },
    },
  },
  methods: {
    getValidText(strOrg) {
      if (!strOrg) return "";

      strOrg = strOrg.trim();
      // 그냥 다 숫자면 그대로 리턴.
      if (getCanConvertNumber(strOrg)) {
        return addCommaForMoney(strOrg);
      }
      let ret = "";
      let char;
      for (var i = 0; i < strOrg.length; ++i) {
        char = strOrg.charAt(i);
        if (!getCanConvertNumber(char)) continue;
        ret += char;
      }
      if (!ret) return "";
      const nRet = Number.parseInt(ret, 10);
      return addCommaForMoney(nRet);
    },
    blur() {
      // 외부에서 호출 될 메서드.
      this.$refs.input.blur();
    },

    onFocus(e) {
      this.$emit("update:focus", e);
    },
    onBlur(e) {
      this.$emit("update:blur", e);
    },
    onKeyUpEnter() {
      this.blur();
    },
  },
};
</script>
<style lang="scss" scoped></style>
