import PhoneModel from "@/models/PhoneModel";
import EmailModel from "@/models/EmailModel";

class MyInfoModel {
  constructor() {
    this.objRawTenderPartner = {};

    this.tenderPartner = {
      corporateNum: "",
      companyName: "",
      ceo: "",
      business: "",
      businessType: "",
      address: "",
      address2: "",
      zipcode: "",
      tel: new PhoneModel(),
      fax: new PhoneModel(),
      email: new EmailModel(),
      siteUrl: "",
      businessRegFileList: [],
      bankBookFileList: [],
      attachFileList: [],
    };

    // this.attachFileList = [];
  }

  setData(obj, attachFileList) {
    this.objRawTenderPartner = obj;

    if (obj.corporateNum) this.tenderPartner.corporateNum = obj.corporateNum;
    if (obj.companyName) this.tenderPartner.companyName = obj.companyName;
    if (obj.ceo) this.tenderPartner.ceo = obj.ceo;
    if (obj.business) this.tenderPartner.business = obj.business;
    if (obj.businessType) this.tenderPartner.businessType = obj.businessType;
    if (obj.address) this.tenderPartner.address = obj.address;
    if (obj.address2) this.tenderPartner.address2 = obj.address2;
    if (obj.zipcode) this.tenderPartner.zipcode = obj.zipcode;

    if (obj.tel) this.tenderPartner.tel.setDataByString(obj.tel);
    if (obj.fax) this.tenderPartner.fax.setDataByString(obj.fax);
    if (obj.email) this.tenderPartner.email.setDataByString(obj.email);

    if (obj.siteUrl) this.tenderPartner.siteUrl = obj.siteUrl;

    if (obj.attachFileList) {
      this.tenderPartner.attachFileList = obj.attachFileList;
    }

    if (obj.businessRegFile) {
      this.tenderPartner.businessRegFileList = [obj.businessRegFile];
    }
    if (obj.bankBookFile) {
      this.tenderPartner.bankBookFileList = [obj.bankBookFile];
    }
  }

  getTenderPartner() {
    let obj = {
      zipcode: this.tenderPartner.zipcode,

      corporateNum: this.tenderPartner.corporateNum,
      companyName: this.tenderPartner.companyName,
      ceo: this.tenderPartner.ceo,
      business: this.tenderPartner.business,
      businessType: this.tenderPartner.businessType,
      address: this.tenderPartner.address,
      address2: this.tenderPartner.address2,
      siteUrl: this.tenderPartner.siteUrl,
      // businessRegFileList: this.tenderPartner.businessRegFileList,
      // bankBookFileList: this.tenderPartner.bankBookFileList,
      attachFileList: this.tenderPartner.attachFileList,
    };

    if (this.tenderPartner.tel.getIsValid()) {
      obj.tel = this.tenderPartner.tel.getTextFull();
    }
    if (this.tenderPartner.fax.getIsValid()) {
      obj.fax = this.tenderPartner.fax.getTextFull();
    }
    if (this.tenderPartner.email.getIsValid()) {
      obj.email = this.tenderPartner.email.getTextFull();
    }

    if (this.tenderPartner.businessRegFileList.length > 0) {
      obj.businessRegFile = this.tenderPartner.businessRegFileList[0];
    }
    if (this.tenderPartner.bankBookFileList.length > 0) {
      obj.bankBookFile = this.tenderPartner.bankBookFileList[0];
    }
    // if( this.attachFileList.length > 0 ) {
    //   obj.attachFileList = this.tenderPartner.attachFileList;
    // }

    obj = Object.assign({}, this.objRawTenderPartner, obj);

    return obj;
  }

  getAttachFileList() {
    return this.attachFileList;
  }

  getAlertMessage() {
    if (!this.tenderPartner.companyName) return "상호를 입력해주세요.";
    if (!this.tenderPartner.ceo) return "대표자를 입력해주세요.";
    if (!this.tenderPartner.tel.getIsValid()) return "전화번호를 입력해주세요.";
    if (!this.tenderPartner.email.getIsValid()) return "대표 이메일을 입력해주세요.";
    if (!this.tenderPartner.business) return "업태를 입력해주세요.";
    if (!this.tenderPartner.businessType) return "업종을 입력해주세요.";

    if (!this.tenderPartner.address) return "주소를 확인해주세요.";
    if (!this.tenderPartner.address2) return "주소를 확인해주세요.";
    if (!this.tenderPartner.zipcode) return "주소를 확인해주세요.";

    if (this.tenderPartner.businessRegFileList.length < 1) return "사업자등록증을 확인해주세요.";
    if (this.tenderPartner.bankBookFileList.length < 1) return "통장사본을 확인해주세요.";

    return null;
  }
}

export default MyInfoModel;
