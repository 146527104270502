<template>
  <div class="group_form">
    <Input :isDisabled="isDisabled" style="width: 110px" :value.sync="emailNameSync" />
    <span class="txt_view_c w27">@</span>
    <Input
      class="w121"
      :isDisabled="isDisabled || !isDirectEmailMode"
      :value.sync="emailDomainSync"
    />
    <SelectBox
      :isDisabled="isDisabled"
      class="w120"
      :dataList="emailVendor"
      :value.sync="emailSelectSync"
    />
    <template v-if="isMailPushBtn">
      <button type="button" class="btn_fourthly btn_small" @click="onClickMailConfirm">
        인증 메일 발송
      </button>
      <strong class="txt_view_r tc_red">* 이메일 인증이 완료되어야 회원가입이 됩니다.</strong>
    </template>
  </div>
</template>

<script>
import Input from "@/components/common/input/Input";
import SelectBox from "@/components/common/selectBox/SelectBox";
import { mapState } from "vuex";
import { COMMON_CODE_ACTION } from "@/store/modules/common/action";

export default {
  name: "InputEmail",
  components: {
    Input,
    SelectBox,
  },
  props: {
    emailName: String,
    emailDomain: String,
    isMailPushBtn: Boolean,
    isDisabled: Boolean,
  },
  data() {
    return {
      emailSelect: "직접입력",
    };
  },
  mounted() {
    const { GET_EMAIL_VENDOR } = COMMON_CODE_ACTION;

    this.$store.dispatch(GET_EMAIL_VENDOR);
  },

  computed: {
    ...mapState({
      emailVendor: (state) => state.commonCode.emailVendor,
    }),
    isDirectEmailMode() {
      return this.emailSelect === "직접입력";
    },
    emailNameSync: {
      get() {
        return this.emailName;
      },
      set(value) {
        this.$emit("update:emailName", value);

        this.$emit("onChanged", `${value}@${this.emailDomain}`);
      },
    },
    emailDomainSync: {
      get() {
        const hasDomain = this.emailVendor.some((item) => item.code === this.emailDomain);
        if (!hasDomain) {
          this.setEmailSelect("직접입력");
        } else {
          this.setEmailSelect(this.emailDomain);
        }
        return this.emailDomain;
      },
      set(value) {
        this.$emit("update:emailDomain", value);

        this.$emit("onChanged", `${this.emailName}@${value}`);
      },
    },
    emailSelectSync: {
      get() {
        return this.emailSelect;
      },
      set(value) {
        this.emailSelect = value;

        if (this.isDirectEmailMode) {
          this.$emit("update:emailDomain", "");
        } else {
          this.$emit("update:emailDomain", value);
        }

        this.$emit("onChanged", `${this.emailName}@${value}`);
      },
    },
  },
  methods: {
    onClickMailConfirm() {
      this.$emit("onClickMailConfirm");
    },
    setEmailSelect(data) {
      this.emailSelect = data;
    },
  },
};
</script>
