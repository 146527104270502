// export const MAX_LENGTH_TITLE = 100;
// export const MAX_LENGTH_DETAIL = 2000;
// export const MAX_LENGTH_MEMO = 1000; // 비고.

// export const MAX_LENGTH_MANAGER_NAME = 10;

export const MAX_LENGTH_URL = 200;

export const MAX_LENGTH_REASON = 100;

export const MAX_LENGTH_TITLE = 100;

export const MAX_LENGTH_PRICE = 20; // 환율 + 16 = 20자.

export const MAX_LENGTH_MANAGER_NAME = 10;
export const MAX_LENGTH_DETAIL = 1000;
export const MAX_LENGTH_MEMO = 1000; // 비고.
export const MAX_LENGTH_QUANTITY = 21; // 실제 서버에 보내는 숫자 자리 수: 16, 16자리를 입력하면 콤마가 5개 생김.
export const MAX_LENGTH_UNIT_PRICE = 21; // 실제 서버에 보내는 숫자 자리 수: 16, 16자리를 입력하면 콤마가 5개 생김.

export const MAX_LENGTH_QUANTITY_NO_COMMA = 16; // 실제 서버에 보내는 숫자 자리 수: 16, 16자리를 입력하면 콤마가 5개 생김.
