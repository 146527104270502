import ApiService from "@/services/ApiService";
// import ManagerModel from '@/components/shared/managerInput/ManagerModel'
import ManagerViewModel from "@/components/shared/managerInput/ManagerViewModel";

const BidItemMixin = {
  data() {
    return {
      itemData: {},

      postCid: "",

      managerInfo: {
        dataList: [],
      },
    };
  },
  beforeMount() {
    this.postCid = this.$route.params.id;

    this.getData(this.postCid);
  },
  methods: {
    refresh() {
      this.getData(this.postCid);
    },
    async getData(postCid) {
      const path = `${this.$apiPath.BID}/${postCid}`;
      const result = await ApiService.shared.getData(path);
      if (!result.data) return;

      this.itemData = result.data;

      this.setDataFromServer(this.itemData);
    },
    async cancelBid() {
      const path = `${this.$apiPath.BID}/${this.postCid}`;

      const result = await ApiService.shared.deleteData(path);
      if (result.code !== "200") {
        this.alert(result.text);
        return false;
      }

      return true;
    },
    goToList(isWithQuery) {
      const route = {
        path: this.$routerPath.BID_LIST,
      };
      if (isWithQuery) {
        route.query = this.$route.query;
      }

      this.$router.push(route);
    },
    // setManagerList( tenderPersonList ) {
    //   var arr = [];
    //   var managerModel;

    //   for(var i=0; i<tenderPersonList.length; ++i) {
    //     managerModel = new ManagerModel();

    //     managerModel.setDataFromServer( tenderPersonList[ i ] );

    //     arr.push( managerModel );
    //   }

    //   this.managerInfo.dataList = arr;
    // },
    setManagerList(tenderPersonList) {
      var arr = [];
      var managerModel;

      for (var i = 0; i < tenderPersonList.length; ++i) {
        managerModel = new ManagerViewModel();

        managerModel.setDataFromServer(tenderPersonList[i]);

        arr.push(managerModel);
      }

      this.managerInfo.dataList = arr;
    },
  },
};

export default BidItemMixin;
