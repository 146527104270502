<template>
  <TableViewWithTitle title="내 정보">
    <template v-slot:headLeft>
      <p class="desc_tip tc_red">
        <span class="ico_purchase ico_exclam_r" />내 정보 수정 시 우측 상단 내 정보 수정에도
        동일하게 반영됩니다.
      </p>
    </template>
    <template v-slot:body>
      <tr>
        <th>ID (사업자 번호)</th>
        <td colspan="3">
          <div class="group_form">
            <span class="txt_view">{{ tenderPartner.corporateNum }}</span>
            <span class="txt_view_r tc_red">* ID (사업자 번호)는 수정이 불가능합니다.</span>
          </div>
        </td>
      </tr>
      <tr>
        <th>상호<span class="ico_purchase ico_star">필수항목</span></th>
        <td>
          <Input :value.sync="tenderPartner.companyName" />
        </td>
        <th>대표자<span class="ico_purchase ico_star">필수항목</span></th>
        <td>
          <Input :value.sync="tenderPartner.ceo" />
        </td>
      </tr>
      <tr>
        <th>대표 전화번호<span class="ico_purchase ico_star">필수항목</span></th>
        <td>
          <InputPhone
            :useMobile="true"
            :useArea="true"
            :phone0.sync="tenderPartner.tel.phone0"
            :phone1.sync="tenderPartner.tel.phone1"
            :phone2.sync="tenderPartner.tel.phone2"
          />
        </td>
        <th>FAX</th>
        <td>
          <InputPhone
            :useMobile="false"
            :useArea="true"
            :phone0.sync="tenderPartner.fax.phone0"
            :phone1.sync="tenderPartner.fax.phone1"
            :phone2.sync="tenderPartner.fax.phone2"
          />
        </td>
      </tr>
      <tr>
        <th>주소<span class="ico_purchase ico_star">필수항목</span></th>
        <td colspan="3">
          <InputAddress
            :zipcode.sync="tenderPartner.zipcode"
            :address.sync="tenderPartner.address"
            :address2.sync="tenderPartner.address2"
          />
        </td>
      </tr>
      <tr>
        <th>대표 이메일<span class="ico_purchase ico_star">필수항목</span></th>
        <td colspan="3">
          <InputEmail
            :emailName.sync="tenderPartner.email.name"
            :emailDomain.sync="tenderPartner.email.domain"
          />
        </td>
      </tr>
      <tr>
        <th>업태<span class="ico_purchase ico_star">필수항목</span></th>
        <td>
          <Input :value.sync="tenderPartner.business" />
        </td>
        <th>업종<span class="ico_purchase ico_star">필수항목</span></th>
        <td>
          <Input :value.sync="tenderPartner.businessType" />
        </td>
      </tr>
      <tr>
        <th>사업자등록증<span class="ico_purchase ico_star">필수항목</span></th>
        <td>
          <FileWrite
            :canMultiple="false"
            :dataList="tenderPartner.businessRegFileList"
            :invalidFileExtension="invalidFileExtensions"
            @fileUploaded="(file) => (tenderPartner.businessRegFileList = file)"
            @fileRemoved="(fileToRemove) => (tenderPartner.businessRegFileList = [])"
          />
        </td>
        <th>통장사본<span class="ico_purchase ico_star">필수항목</span></th>
        <td>
          <FileWrite
            :canMultiple="false"
            :dataList="tenderPartner.bankBookFileList"
            :invalidFileExtension="invalidFileExtensions"
            @fileUploaded="(file) => (tenderPartner.bankBookFileList = file)"
            @fileRemoved="(fileToRemove) => (tenderPartner.bankBookFileList = [])"
          />
        </td>
      </tr>
      <tr>
        <th>첨부 파일</th>
        <td colspan="3">
          <FileWrite
            :dataList="tenderPartner.attachFileList"
            @fileUploaded="fileUploaded"
            @fileRemoved="fileRemoved"
          />
        </td>
      </tr>
      <tr>
        <th>참고 URL</th>
        <td colspan="3">
          <Input
            :maxLength="MAX_LENGTH_URL"
            placeholder="회사 홈페이지 주소을 입력하세요"
            :value.sync="tenderPartner.siteUrl"
          />
        </td>
      </tr>
    </template>
  </TableViewWithTitle>
</template>

<script>
import TableViewWithTitle from "@/components/shared/tableView/TableViewWithTitle";
import Input from "@/components/common/input/Input";
import InputEmail from "@/components/common/input/InputEmail";
import InputPhone from "@/components/common/input/InputPhone";
import FileWrite from "@/components/common/file/FileWrite";
import InputAddress from "@/components/common/input/InputAddress";

import MyInfoModel from "@/components/user/bid/write/MyInfoModel";
import { file } from "@/constants/options";
import { MAX_LENGTH_URL } from "@/constants/commonTextLength";

export default {
  name: "MyInfo",
  components: {
    TableViewWithTitle,
    Input,
    InputEmail,
    InputPhone,
    InputAddress,
    FileWrite,
  },
  props: {
    myId: String,
    model: MyInfoModel,
  },
  computed: {
    invalidFileExtensions() {
      return file.INVALID_FILE_EXTENSIONS;
    },
    tenderPartner() {
      return this.model.tenderPartner;
    },
    MAX_LENGTH_URL() {
      return MAX_LENGTH_URL;
    },
  },
  methods: {
    fileUploaded(files) {
      this.model.tenderPartner.attachFileList =
        this.model.tenderPartner.attachFileList.concat(files);
    },
    fileRemoved(itemToRemove) {
      this.model.tenderPartner.attachFileList = this.model.tenderPartner.attachFileList.filter(
        (item) => item !== itemToRemove,
      );
    },
  },
};
</script>
