<template>
  <PageWithLayout>
    <div class="section_comm">
      <div class="head_section">
        <h2 id="kakaoBody" class="tit_section">입찰 참여</h2>
      </div>
      <div class="body_section">
        <div :class="['area_fold_r', { folded: isFolded }]">
          <button type="button" class="btn_fold" @click="onClickFold">
            입찰공고 <span class="txt_fold">{{ isFolded ? "펼쳐보기" : "닫기" }}</span
            ><span class="ico_purchase ico_arr_fold" />
          </button>
          <AnnounceInfo :itemData="itemData.postBoard || {}" />
          <div class="bar_fold" @click="onClickFold">
            <span class="ico_purchase ico_arr_fold2" />
          </div>
        </div>
        <MyInfo :model="myInfo" />
        <ManagerInfo :dataProps="managerInfo" />
        <BidInfo :model="bidInfo" />
      </div>
    </div>
    <Sticky>
      <div class="area_left">
        <button type="button" class="btn_fourthly btn_large" @click="onClickGoToList">목록</button>
      </div>
      <div class="area_right">
        <button
          v-if="isFinished"
          type="button"
          class="btn_secondary btn_large"
          @click="onClickBidCancel"
        >
          입찰 취소
        </button>
        <button
          v-if="!isFinished"
          type="button"
          class="btn_secondary btn_large"
          @click="onClickTempSave"
        >
          임시 저장
        </button>
        <!-- <button type="button" class="btn_primary btn_large">수정</button> -->
        <button v-if="canSubmit" type="button" class="btn_primary btn_large" @click="onClickSubmit">
          제출
        </button>
      </div>
    </Sticky>
    <!-- <template v-slot:popup>
      <AlertPopup v-if="isPopAlertTempSave"
        alertText="임시저장이 완료되었습니다.<br><br>* 제출 버튼을 클릭하여 최종 제출을 하시기 바랍니다.<br>제출이 된 후에도 다시 수정할 수 있으나 구매자의 요청으로 강제 마감 될 수 있습니다."
        btnPrimaryText="확인"
        @onClickPrimary="onClickAlertConfirm" />
    </template> -->
  </PageWithLayout>
</template>

<script>
import PageWithLayout from "@/components/layout/PageWithLayout";

import AnnounceInfo from "@/components/user/shared/AnnounceInfo";
import MyInfo from "@/components/user/bid/write/MyInfo";
import ManagerInfo from "@/components/user/bid/view/ManagerInfo";
import BidInfo from "@/components/user/bid/write/BidInfo";
import Sticky from "@/components/layout/content/Sticky";
// import AlertPopup from '@/components/layout/popup/AlertPopup'

import PageMixin from "@/mixins/PageMixin";
import BidItemMixin from "@/mixins/bid/BidItemMixin";
import ApiService from "@/services/ApiService";
import MyInfoModel from "@/components/user/bid/write/MyInfoModel";
import BidInfoModel from "@/components/user/bid/write/BidInfoModel";

import { ALERT_ACTION } from "@/store/modules/alert/action";

export default {
  name: "BidWrite",
  components: {
    PageWithLayout,
    AnnounceInfo,
    MyInfo,
    ManagerInfo,
    BidInfo,
    Sticky,
    // AlertPopup,
  },
  mixins: [PageMixin, BidItemMixin],
  data() {
    return {
      isFolded: true,
      // isPopAlertTempSave: false,

      myInfo: new MyInfoModel(),
      bidInfo: new BidInfoModel(),
    };
  },
  computed: {
    isFinished() {
      const { postTender } = this.itemData;

      if (!postTender) return false;

      return postTender.status === "F";
    },
    canSubmit() {
      if (!this.itemData.postBoard) return false;
      if (!this.itemData.postTender) return false;

      // 공고의 입찰진행 중일때만 수정 가능.
      if (this.itemData.postBoard.status !== "S") return false;

      return true;
    },
  },
  methods: {
    setDataFromServer(dataFromServer) {
      const { note, price, estimateFile, proposalFileList, tenderPartner, tenderPersonList } =
        dataFromServer.postTender;

      // 통화의 기준을 공고에서 가져오겠다.
      const { currency } = dataFromServer.postBoard;

      // 이유는 모르겠지만 attachFileList만 postTender 안에.
      this.myInfo.setData(tenderPartner);

      this.bidInfo.setData({ note, price, estimateFile, proposalFileList, currency });

      if (tenderPersonList && tenderPersonList.length > 0) this.setManagerList(tenderPersonList);
    },
    makeParamsForSave() {
      let obj = {
        postCid: this.postCid,

        // 확인 필요.
        userNum: this.itemData.postTender.userNum,
      };

      obj.tenderPartner = this.myInfo.getTenderPartner();
      // obj.attachFileList = this.myInfo.getAttachFileList();

      const bidInfo = this.bidInfo.getData();
      obj = Object.assign({}, obj, bidInfo);

      const managerList = this.managerInfo.dataList;

      // const arrManagerForServer = managerList.map( item => item.getDataForServer() );
      // if( arrManagerForServer.length > 0 )  obj.tenderPersonList = arrManagerForServer;

      if (managerList.length > 0) obj.tenderPersonList = managerList;

      return obj;
    },
    onClickTempSave() {
      const isTemp = true;
      this.saveData(isTemp);
    },
    onClickSubmit() {
      const alertMessage = this.getAlertMessage();

      if (alertMessage) {
        this.alert(alertMessage);
        return;
      }

      const params = {
        text: "입력한 입찰정보 내용으로 제출하시겠습니까?",
        onClickY: this.onClickSubmitOk,
      };
      this.$store.dispatch(ALERT_ACTION.SHOW_ALERT_YN, params);
    },
    onClickSubmitOk() {
      const isTemp = false;
      this.saveData(isTemp);
    },
    async saveData(isTemp) {
      const obj = this.makeParamsForSave();

      const path = `${this.$apiPath.BID}/${this.postCid}?temp=${isTemp ? "Y" : "N"}`;

      let result;

      if (this.isFinished) {
        result = await ApiService.shared.putData(path, obj);
      } else {
        result = await ApiService.shared.postData(path, obj);
      }

      if (result.code !== "200") {
        this.alert(result.text);
        return;
      }

      // 저장 성공 시 그냥 목록으로.
      this.goToList(false);

      // if( isTemp ) {
      //   this.isPopAlertTempSave = true;
      // } else {
      //   this.goToList( false );
      // }
    },
    async onClickBidCancel() {
      const isSuccess = await this.cancelBid();
      if (!isSuccess) return;

      this.refresh();
    },
    getAlertMessage() {
      const alertMessMyInfo = this.myInfo.getAlertMessage();
      if (alertMessMyInfo) return alertMessMyInfo;

      const alertMessBidInfo = this.bidInfo.getAlertMessage();
      if (alertMessBidInfo) return alertMessBidInfo;

      return null;
    },
    getAlertMessageManagerInfo(obj) {
      const dataList = obj.dataList;
      let managerModel;

      const defaultMess = "담당자 정보를 확인해주세요.";

      for (let i = 0; i < dataList.length; ++i) {
        managerModel = dataList[i];

        if (!managerModel.name) return defaultMess;
        if (!managerModel.job) return defaultMess;

        const mobileModel = managerModel.mobile;
        if (!mobileModel.getIsValid()) return defaultMess;

        const emailModel = managerModel.email;
        if (!emailModel.getIsValid()) return defaultMess;
      }

      return null;
    },
    onClickGoToList() {
      this.goToList(true);
    },
    onClickFold(e) {
      if (this.isFolded) {
        this.isFolded = false;
      } else {
        this.isFolded = true;
      }
    },
    // onClickAlertConfirm() {
    //   this.isPopAlertTempSave = false;
    // }
  },
};
</script>
