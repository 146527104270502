<template>
  <div :class="['group_form group_count', { on: isDirectMode }]">
    <SelectBox
      :class="selectWidth || 'w90'"
      :dataList="currencyList"
      :value.sync="selectedValue"
      :isDisabled="isDisabledSelectBox"
      :placeholder="placeholder"
      @update:value="onChangeSelect"
    />
    <Input
      v-if="isDirectMode"
      :class="directInputWidth || 'w90'"
      placeholder="통화코드"
      :value.sync="valueSync"
      :isDisabled="isDisabledSelectBox"
    />
    <InputAutoMoney
      v-if="isAutoMoneyType"
      :class="isDirectMode ? isDirectModeInputWidth || 'w380' : inputWidth || 'w380'"
      :maxLength="inputMaxLength"
      :value.sync="countSync"
      :placeholder="placeholderCount"
      :isDisabled="isDisabledInputCount"
    />
    <InputNumber
      v-else
      :class="isDirectMode ? isDirectModeInputWidth || 'w380' : inputWidth || 'w380'"
      :value.sync="countSync"
      :placeholder="placeholderCount"
      :isDisabled="isDisabledInputCount"
    />
    <slot />
  </div>
</template>

<script>
import SelectBox from "./SelectBox";
import Input from "@/components/common/input/Input";
import InputNumber from "@/components/common/input/InputNumber";
import InputAutoMoney from "@/components/common/input/InputAutoMoney";

import { mapState } from "vuex";
import { COMMON_CODE_ACTION } from "@/store/modules/common/action";
import { ALERT_ACTION } from "@/store/modules/alert/action";
import { getMoneyToNumber } from "@/utils/stringUtils";

export default {
  // tableView에 들어가는 예산,투찰금액 등 '화폐단위 + 금액'
  name: "SelectBoxCurrencyIncludeData",
  components: {
    SelectBox,
    Input,
    InputNumber,
    InputAutoMoney,
  },
  props: {
    value: String,
    selectWidth: String,
    directInputWidth: String,
    inputWidth: String,
    inputMaxLength: Number,
    maxCount: [Number, String],
    maxCountErrorText: String,
    isDirectModeInputWidth: String,
    placeholder: String,
    placeholderCount: String,
    isDisabledSelectBox: {
      type: Boolean,
      default: false,
    },
    isDisabledInputCount: {
      type: Boolean,
      default: false,
    },
    count: [Number, String],
    isAutoMoneyType: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedValue: "KRW",
    };
  },
  mounted() {
    this.$store.dispatch(COMMON_CODE_ACTION.GET_CURRENCY);

    this.setValueByProps();
  },
  computed: {
    ...mapState({
      currencyList: (state) => state.commonCode.currency,
    }),
    isDirectMode() {
      return this.selectedValue === "직접입력";
    },
    countSync: {
      get() {
        let ret = "";
        if (this.maxCount && this.maxCount < getMoneyToNumber(this.count)) {
          this.$store.dispatch(ALERT_ACTION.SHOW_ALERT, {
            text: this.maxCountErrorText || "error",
          });
          ret = String(this.maxCount);
        } else {
          ret = this.count;
        }

        return ret;
      },
      set(value) {
        this.$emit("update:count", value);
      },
    },
    currencySync: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("update:value", value);
      },
    },
    valueSync: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("update:value", value);
      },
    },
  },
  watch: {
    value(newVal, oldVal) {
      this.setValueByProps();
    },
  },
  methods: {
    onChangeSelect(value) {
      if (this.isDirectMode) {
        this.$emit("update:value", "");
      } else {
        this.$emit("update:value", value);
      }
    },
    setValueByProps() {
      var toSelectedValue;
      switch (this.value) {
        case "KRW":
        case "USD":
        case "EUR":
        case "JPY":
        case "CNY":
          toSelectedValue = this.value;
          break;
        default:
          toSelectedValue = "직접입력";
      }

      if (this.selectedValue !== toSelectedValue) {
        this.selectedValue = toSelectedValue;
      }
    },
  },
};
</script>
