<template>
  <fragment>
    <TableViewWithTitle title="입찰공고 정보">
      <template v-slot:body>
        <tr>
          <th>입찰 공고번호</th>
          <td>{{ itemData.postCid }}</td>
          <th>입찰 시작일</th>
          <td>{{ startDate }}</td>
        </tr>
        <tr>
          <th>입찰 공고명</th>
          <td class="td_text" v-html="itemData.title" />
          <th>입찰 마감일</th>
          <td>{{ endDate }}</td>
        </tr>
        <tr>
          <th>분류</th>
          <td colspan="3">
            {{ category }}
          </td>
        </tr>
      </template>
    </TableViewWithTitle>
    <TableViewWithTitle title="담당자 정보">
      <template v-slot:header>
        <tr>
          <th colspan="2">구매 담당</th>
          <th colspan="2">현업 담당</th>
        </tr>
      </template>
      <template v-slot:body>
        <tr>
          <th>구매 담당자</th>
          <td>{{ managerPurchase.name }}</td>
          <th>현업 담당자</th>
          <td>{{ realManagerPurchase.name }}</td>
        </tr>
        <tr>
          <th>전화번호 (내선)</th>
          <td>{{ managerPurchase ? managerPurchase.tel : "" }}</td>
          <th>전화번호 (내선)</th>
          <td>{{ realManagerPurchase ? realManagerPurchase.tel : "" }}</td>
        </tr>
        <tr>
          <th>이메일</th>
          <td class="td_text">
            {{ managerPurchase ? managerPurchase.email : "" }}
          </td>
          <th>이메일</th>
          <td class="td_text">
            {{ realManagerPurchase ? realManagerPurchase.email : "" }}
          </td>
        </tr>
      </template>
    </TableViewWithTitle>
    <TableViewWithTitle title="입찰 상품 정보">
      <template v-slot:body>
        <tr>
          <th>구매품목</th>
          <td class="td_text">
            {{ itemData.itemName }}
          </td>
          <th>입찰수량</th>
          <td>
            {{ getCountWithQuantity(itemData.quantity, itemData.unitValue) }}
          </td>
        </tr>
        <tr>
          <th>예산</th>
          <td colspan="3">
            {{ getCountWithCurrency(itemData.budget, itemData.currency) }}
          </td>
        </tr>
        <tr>
          <th>RFP</th>
          <td colspan="3">
            <FileView :dataList="itemData.attachFileList" />
          </td>
        </tr>
      </template>
    </TableViewWithTitle>
    <TableViewWithTitle title="입찰상품 추가 정보">
      <template v-slot:body>
        <tr>
          <td colspan="4" class="td_full h77 td_text" v-html="noteDecoded" />
        </tr>
      </template>
    </TableViewWithTitle>
  </fragment>
</template>

<script>
import TableViewWithTitle from "@/components/shared/tableView/TableViewWithTitle";
import FileView from "@/components/common/file/FileView";
import { getDateString } from "@/utils/dateUtils";
import { addCommaForMoney, decodeHTMLEntities } from "@/utils/stringUtils";
import { getCategoryDepthText, getCountWithCurrency, getCountWithQuantity } from "@/utils/spUtils";

export default {
  name: "AnnounceInfo",
  components: {
    TableViewWithTitle,
    FileView,
  },
  props: {
    itemData: Object,
  },
  computed: {
    noteDecoded() {
      const note = this.itemData.note;

      if (!note) return "";

      const result = decodeHTMLEntities(document, note);
      return result;
    },
    startDate() {
      if (!this.itemData.startDate) return "";
      return getDateString(this.itemData.startDate);
    },
    endDate() {
      if (!this.itemData.endDate) return "";
      return getDateString(this.itemData.endDate);
    },
    // budget() {
    //   if( !this.itemData.budget ) return '';
    //   return addCommaForMoney( String( this.itemData.budget ) );
    // },
    // 구매 담당자
    managerPurchase() {
      const arrPur = this.itemData.purchasePersonList;
      if (!arrPur) return null;

      const itemP = arrPur.find((item) => item.code === "P");
      return itemP;
    },
    // 현업 담당자
    realManagerPurchase() {
      const arrPur = this.itemData.purchasePersonList;
      if (!arrPur) return null;

      const itemP = arrPur.find((item) => item.code === "B");
      return itemP;
    },
    // 물품,하드웨어,서버 -> 물품>하드웨어>서버
    category() {
      if (!this.itemData || !this.itemData.category) return "";
      return getCategoryDepthText(this.itemData.category);
    },
  },
  methods: {
    getCountWithCurrency(count, value) {
      return getCountWithCurrency(count, value);
    },
    getCountWithQuantity(count, value) {
      return getCountWithQuantity(count, value);
    },
  },
};
</script>
