import { getCanConvertNumber } from "./numberUtils";

export function getIsValidPhone(obj) {
  const { phone0, phone1, phone2 } = obj;

  if (!phone0 || !phone0.trim()) return false;
  if (!phone1 || !phone1.trim()) return false;
  if (!phone2 || !phone2.trim()) return false;

  return true;
}

export function getIsValidToSave(obj) {
  const { phone0, phone1, phone2 } = obj;

  if (!phone0 || !phone0.trim()) return false;
  if (!phone1 || !phone1.trim()) return false;
  if (!phone2 || !phone2.trim()) return false;

  if (!getCanConvertNumber(phone0)) return false;
  if (!getCanConvertNumber(phone1)) return false;
  if (!getCanConvertNumber(phone2)) return false;

  return true;
}

export function setPhone(strPhoneNumber, objPhone) {
  const arr = strPhoneNumber.split("-");
  if (arr.length !== 3) return false;

  objPhone.phone0 = arr[0];
  objPhone.phone1 = arr[1];
  objPhone.phone2 = arr[2];

  return true;
}

export function getPhoneTextFull(objPhone) {
  const ret = `${objPhone.phone0}-${objPhone.phone1}-${objPhone.phone2}`;
  return ret;
}

export function getValidText(strOrg) {
  if (!strOrg) return "";

  // 그냥 다 숫자면 그대로 리턴.
  if (getCanConvertNumber(strOrg)) return strOrg;

  let ret = "";

  let char;

  for (let i = 0; i < strOrg.length; ++i) {
    char = strOrg.charAt(i);

    if (char !== "*" && !getCanConvertNumber(char)) continue;

    ret += char;
  }

  return ret;
}

export function getIsValidText(strOrg) {
  if (strOrg === "") return true;

  if (!strOrg) return false;

  const strExceptStar = strOrg.split("*").join("");

  return getCanConvertNumber(strExceptStar);
}
