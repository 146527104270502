<template>
  <div class="group_form">
    <SelectBox
      :isDisabled="isDisabled"
      class="w90"
      placeholder="선택"
      :dataList="phoneSelectList"
      :value.sync="phone0Sync"
    />
    <InputTel :isDisabled="isDisabled" class="w137" :value.sync="phone1Sync" :maxLength="4" />
    <InputTel :isDisabled="isDisabled" class="w137" :value.sync="phone2Sync" :maxLength="4" />
  </div>
</template>

<script>
// import InputNumber from '@/components/common/input/InputNumber'
import InputTel from "@/components/common/input/InputTel";
import SelectBox from "@/components/common/selectBox/SelectBox";
import { mapState } from "vuex";
import { COMMON_CODE_ACTION } from "@/store/modules/common/action";

export default {
  name: "InputPhone",
  components: {
    // InputNumber,
    InputTel,
    SelectBox,
  },
  props: {
    useMobile: Boolean,
    useArea: Boolean,
    phone0: String,
    phone1: String,
    phone2: String,
    isDisabled: Boolean,
  },
  mounted() {
    const { GET_MOBILE_STATION, GET_PHONE_AREA } = COMMON_CODE_ACTION;

    if (this.useMobile) {
      this.$store.dispatch(GET_MOBILE_STATION);
    }

    if (this.useArea) {
      this.$store.dispatch(GET_PHONE_AREA);
    }
  },
  computed: {
    ...mapState({
      mobileStation: (state) => state.commonCode.mobileStation,
      phoneArea: (state) => state.commonCode.phoneArea,
    }),
    phoneSelectList() {
      var arrRet = [];
      if (this.useMobile) {
        arrRet = arrRet.concat(this.mobileStation);
      }
      if (this.useArea) {
        arrRet = arrRet.concat(this.phoneArea);
      }

      return _.unionWith(arrRet, _.isEqual);
    },
    phone0Sync: {
      get() {
        return this.phone0;
      },
      set(value) {
        this.$emit("update:phone0", value);

        this.$emit("onChanged");
      },
    },
    phone1Sync: {
      get() {
        return this.phone1;
      },
      set(value) {
        this.$emit("update:phone1", value);

        this.$emit("onChanged");
      },
    },
    phone2Sync: {
      get() {
        return this.phone2;
      },
      set(value) {
        this.$emit("update:phone2", value);

        this.$emit("onChanged");
      },
    },
  },
};
</script>
