import { getCanConvertNumber } from "@/utils/numberUtils";

export default class PhoneModel {
  constructor(phone0, phone1, phone2) {
    this.phone0 = phone0 || "";
    this.phone1 = phone1 || "";
    this.phone2 = phone2 || "";
  }

  setData(phone0, phone1, phone2) {
    this.phone0 = phone0 || "";
    this.phone1 = phone1 || "";
    this.phone2 = phone2 || "";
  }

  setDataByString(strTextFull, strDiv = "-") {
    const arr = strTextFull.split(strDiv);
    if (arr.length !== 3) return false;

    this.phone0 = arr[0];
    this.phone1 = arr[1];
    this.phone2 = arr[2];

    return true;
  }

  getIsValid() {
    if (!this.phone0) return false;
    if (!this.phone1) return false;
    if (!this.phone2) return false;

    return true;
  }

  getIsValidToSave() {
    if (!this.getIsValid()) return false;

    if (!getCanConvertNumber(this.phone0)) return false;
    if (!getCanConvertNumber(this.phone1)) return false;
    if (!getCanConvertNumber(this.phone2)) return false;

    return true;
  }

  getTextFull(strDiv = "-") {
    if (!this.getIsValid()) return "";

    const ret = `${this.phone0}${strDiv}${this.phone1}${strDiv}${this.phone2}`;
    return ret;
  }
}
