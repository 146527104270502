class BidInfoModel {
  constructor() {
    this.price = "";
    this.estimateFileList = []; // 견적서. 이건 한개만 올림. 즉 Object 형태로 저장해야함.
    this.proposalFileList = [];
    this.note = "";

    this.currency = "KRW";
  }

  setData(obj) {
    if (obj.price) this.price = obj.price;
    if (obj.proposalFileList) this.proposalFileList = obj.proposalFileList;
    if (obj.note) this.note = obj.note;

    if (obj.currency) this.currency = obj.currency;

    if (obj.estimateFile) {
      const arr = [];
      arr.push(obj.estimateFile);

      this.estimateFileList = arr;
    }
  }

  getData() {
    const obj = {
      price: this.price.split(",").join(""),
      proposalFileList: this.proposalFileList,
      note: this.note,
      currency: this.currency,
    };

    if (this.estimateFileList && this.estimateFileList.length > 0) {
      obj.estimateFile = this.estimateFileList[0];
    }

    return obj;
  }

  getAlertMessage() {
    if (!this.price) return "투찰 금액을 입력해주세요.";
    if (this.estimateFileList.length < 1) return "견적서를 첨부해주세요.";

    return null;
  }
}

export default BidInfoModel;
