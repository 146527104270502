<template>
  <TableViewWithTitle title="입찰정보 입력">
    <template v-slot:body>
      <tr>
        <th>투찰 금액<span class="ico_purchase ico_star">필수항목</span></th>
        <td>
          <InputAutoMoney :inputClass="'align_right'" :maxLength="14" :value.sync="model.price" />
        </td>
        <th></th>
        <td></td>
      </tr>
      <tr>
        <th>견적서<span class="ico_purchase ico_star">필수항목</span></th>
        <td>
          <FileWrite
            :canMultiple="false"
            :dataList="model.estimateFileList"
            @fileUploaded="fileUploadedEstimate"
            @fileRemoved="fileRemovedEstimate"
          />
        </td>
        <th>제안서</th>
        <td>
          <FileWrite
            :dataList="model.proposalFileList"
            @fileUploaded="fileUploadedProposal"
            @fileRemoved="fileRemovedProposal"
          />
        </td>
      </tr>
      <tr>
        <th>비고</th>
        <td colspan="3" class="h96">
          <Textarea :isCount="true" :maxLength="158" :value.sync="model.note" />
        </td>
      </tr>
    </template>
    <template v-slot:foot>
      <div class="tbl_foot">
        <p class="desc_tip">
          ※ 입찰 내역 기입 후 제출 버튼을 클릭하면, 해당 공고에 대한 입찰이 진행됩니다.
        </p>
      </div>
    </template>
  </TableViewWithTitle>
</template>

<script>
import TableViewWithTitle from "@/components/shared/tableView/TableViewWithTitle";
import Input from "@/components/common/input/Input";
import SelectBoxCurrencyIncludeData from "@/components/common/selectBox/SelectBoxCurrencyIncludeData";
import Textarea from "@/components/common/textarea/Textarea";
import FileWrite from "@/components/common/file/FileWrite";
import InputAutoMoney from "@/components/common/input/InputAutoMoney";
import BidInfoModel from "@/components/user/bid/write/BidInfoModel";

export default {
  name: "BidInfo",
  components: {
    TableViewWithTitle,
    Input,
    SelectBoxCurrencyIncludeData,
    Textarea,
    FileWrite,
    InputAutoMoney,
  },
  props: {
    model: BidInfoModel,
  },
  methods: {
    fileUploadedEstimate(files) {
      // this.model.estimateFile = this.model.estimateFile.concat( files );
      this.model.estimateFileList = files;
    },
    fileRemovedEstimate(itemToRemove) {
      // this.model.estimateFile = this.model.estimateFile.filter(item => item !== itemToRemove );
      this.model.estimateFileList = [];
    },
    fileUploadedProposal(files) {
      this.model.proposalFileList = this.model.proposalFileList.concat(files);
    },
    fileRemovedProposal(itemToRemove) {
      this.model.proposalFileList = this.model.proposalFileList.filter(
        (item) => item !== itemToRemove,
      );
    },
  },
};
</script>
